.alert {
  border: 0;
  border-radius: 0;
  margin-bottom: 25px;
  padding: 10px;

  .close {
    color: $aviwest-white;
    font-size: 18px;
    text-shadow: none;
  }
}
